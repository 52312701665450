import { Typography } from "../../components";
import * as classes from "./Footer.module.css";

const footerData = {
  menus: {
    "Acceso rápido": [
      { link: "#", label: "Beneficios" },
      { link: "#", label: "Servicios" },
      { link: "#", label: "¿Qué sigue?" },
      { link: "#", label: "Contacto" },
    ],
    "hr chatbot": [
      { link: "#", label: "Descripción" },
      { link: "#", label: "Plantillas" },
      { link: "#", label: "Integración" },
      { link: "#", label: "Customización" },
    ],
    Resources: [
      { link: "#", label: "Documentación" },
      { link: "#", label: "Noticias" },
      { link: "#", label: "Webinars" },
      { link: "#", label: "Casos de estudio" },
    ],
  },
};

export const Footer = () => {
  return (
    <div className={`${classes["footer-container"]} py-5 md:px-28 px-8`}>
      <div className="flex flex-col md:flex-row gap-16 justify-between py-16">
        <div className={`flex flex-col gap-5 text-white md:w-4/12`}>
          hr chatbot
          <Typography typeScale={"tinyText"} className={classes["footer-text"]}>
            Es un sistema integral que permite a las empresas crear agentes
            conversacionales inteligentes adaptados a sus necesidades
            específicas.
          </Typography>
        </div>
        <div className="flex md:gap-11 gap-8">
          {Object.keys(footerData.menus).map((category) => (
            <div className="flex flex-col gap-5" key={category}>
              <span className="text-white font-semibold">{category}</span>
              <ul className="flex flex-col gap-4">
                {footerData.menus[category].map((menuItem, index) => (
                  <li key={index}>
                    <a
                      href={menuItem.link}
                      className={`${classes["footer-text"]} text-xs font-semibold`}
                    >
                      {menuItem.label}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
      <div
        className={`${classes["footer-text"]} text-xs text-center font-semibold`}
      >
        hr chatbot © {new Date().getFullYear()}, Todos los derechos reservados
      </div>
    </div>
  );
};
