import * as classes from "./Experiences.module.css";
import { Typography } from "../../components";

const Experiences = () => {
  return (
    <div
      className={`${classes.mainContainer} md:px-28 px-8 md:pt-48 pt-36 md:pb-72 pb-32`}
    >
      <div className={`${classes.textContainer} w-full md:w-7/12`}>
        <div className="flex flex-col gap-8">
          <h1 className={"text-white mt-3"}>
            Optimiza las Experiencias Conversacionales con{" "}
            <span className={classes.specialText}>hr chatbot</span>
          </h1>
          <Typography
            typeScale={"subText"}
            className={`${classes.subText2} w-full md:w-7/12`}
          >
            Nuestro avanzado chatbot te permite diseñar agentes
            conversacionales inteligentes adaptados a las necesidades de tu
            empresa. Aprovecha funciones impulsadas por IA y una integración
            fluida para mejorar el soporte al cliente y la interacción.
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default Experiences;
