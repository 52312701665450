import GrowCard from "../../components/GrowCard/GrowCard";
import { Typography } from "../../components";
import Target from "../../assets/Target.svg";
import Dollar from "../../assets/Dollar.svg";
import Shield from "../../assets/Shield.svg";
import Chat from "../../assets/Chat.svg";
import Bot from "../../assets/Bot.svg";
import Analytics from "../../assets/Analytics.svg";
import * as classes from "./ServiceGrow.module.css";

const cardsData = [
  { content: "Plantillas de Chatbots", img: Target },
  { content: "IA Conversacional", img: Chat },
  { content: "Analíticas y Reportes", img: Analytics },
  { content: "Soporte al Cliente 24/7", img: Shield },
  { content: "Integración Sin Esfuerzo", img: Dollar },
  { content: "Flujos de Trabajo Automatizados", img: Bot },
];

export const ServicesFlowGrow = () => {
  return (
    <div
      className={`${classes["main-container"]} px-4 md:px-28 md:pt-32 pt-0 md:pb-44 pb-8`}
    >
      <div className="flex flex-col md:flex-row gap-12 justify-between">
        <div className="flex flex-col gap-3">
          <h1 className="text-white">
            Es hora de agilizar procesos con{" "}
            <span className={classes["specialText"]}>hr chatbot</span>
          </h1>
          <Typography typeScale={"subText"} className={`${classes["subHeading"]}`}>
            Aprovecha todo el potencial de la IA conversacional y optimiza tus
            interacciones con tus empleados gracias a nuestra completa plataforma.
          </Typography>
        </div>
        <div className="flex flex-wrap gap-6 justify-center md:justify-end">
          {cardsData.map((card, index) => (
            <GrowCard
              key={index}
              content={card.content}
              icon={card.img}
              width={300}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ServicesFlowGrow;
