.textContainer {
  color: #fff;
}

.subText2 {
  color: #dddddd;
}

.mainContainer {
  background-image: url("../../assets/Gradient3.svg");
  background-repeat: no-repeat;
  background-position: left;
  background-size: contain;
}

.specialText {
  background-image: linear-gradient(90deg, #ffe000 0%, #7dff16);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
