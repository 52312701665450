import * as classes from "./BenefistChatbot.module.css";
import num1 from "../../assets/Number.svg";
import num2 from "../../assets/Number-1.svg";
import num3 from "../../assets/Number-2.svg";
import InfoCard from "../../components/InfoCard";
import { Typography } from "../../components";

const BenefistChatbot = () => {
  const data = [
    {
      heading: "Plantillas de Chatbot Personalizables",
      content:
        "Agiliza tu proceso de creación de chatbots con nuestra amplia biblioteca de plantillas preconstruidas. Personaliza y despliega fácilmente agentes conversacionales inteligentes adaptados a las necesidades de tu negocio.",
      img: num1,
    },
    {
      heading: "Integración de IA Conversacional",
      content:
        "Aprovecha el poder de algoritmos avanzados de inteligencia artificial para crear chatbots que mantengan diálogos similares a los humanos. Nuestra plataforma integra capacidades de IA conversacional de vanguardia para mejorar la experiencia del usuario.",
      img: num2,
    },
    {
      heading: "Flujos de Trabajo Automatizados y Analíticas",
      content:
        "Automatiza tareas repetitivas, optimiza flujos conversacionales y obtén información valiosa con nuestras analíticas integradas. Potencia a tu equipo de soporte al cliente y toma decisiones basadas en datos para mejorar el rendimiento de los chatbots.",
      img: num3,
    },
  ];

  return (
    <div className={`py-10 md:pb-20 px-8 md:px-28`}>
      <div
        className={`${classes.wrapper} md:mt-36 mt-24 mb-10 flex flex-col gap-10`}
      >
        <div className={`flex flex-col gap-8 items-center`}>
          <h1 className="text-center">
            Mejore la experiencia de su empresa con{" "}
            <span className={classes.specialText}>hr chatbot</span>
          </h1>
          <Typography
            typeScale={"subText"}
            className={`${classes.subText2} text-center text-sm w-full md:w-8/12`}
          >
            Transforme su atención en la empresa en recursos humanos con nuestro
            innovador creador de chatbots. Aproveche agentes conversacionales
            con tecnología de inteligencia artificial, flujos de trabajo
            automatizados y análisis avanzados para ofrecer interacciones
            fluidas y personalizadas.
          </Typography>
        </div>
        <div className={`flex gap-10 flex-wrap px-5 md:px-0 justify-center`}>
          {data.map((item, key) => (
            <InfoCard
              key={key}
              title={item.heading}
              description={item.content}
              icon={item.img}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default BenefistChatbot;
