import * as classes from "./ImplementsChatbot.module.css";
import { Typography } from "../../components/Typography";

const ImplementsChatbot = () => {
  return (
    <div className={`${classes.mainContainer} md:px-28 px-8 md:py-28 py-24`}>
      <div className={classes.textContainer}>
        <div className="flex flex-col gap-5 mt-3">
          <h1 className={`md:w-8/12`}>
            Implementa tu chatbot sin esfuerzo con{" "}
            <span className={classes.specialText}>
              Automatización Impulsada por IA
            </span>
          </h1>
          <div className={`md:flex items-start justify-between`}>
            <div className="md:w-4/12">
              <Typography typeScale={"subText"} className={classes.subText2}>
                Aprovecha funciones impulsadas por IA y una integración fluida
                con tus flujos de trabajo existentes para ofrecer soporte al
                cliente las 24 horas, los 7 días de la semana.
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImplementsChatbot;
