import { Button } from "../../components/Button";

const TestNow = () => {
  return (
    <div className={`md:px-28 px-8 md:pt-28 pt-16 pb-16`}>
      <div className="flex flex-col md:gap-24 gap-16">
        <div
          className={`flex flex-col md:flex-row gap-5 md:gap-0 items-center justify-between`}
        >
          <div
            className={`flex flex-col gap-5 md:gap-2 justify-center md:w-5/12`}
          >
            <h1 className="text-white text-4xl text-center md:text-left">
              Eleva tus Interacciones con los Clientes con hr chatbot
            </h1>
            <p
              className={`text-sm text-center md:text-left`}
              style={{ color: "#918989" }}
            >
              Descubre todo el potencial de la IA conversacional y mejora el
              compromiso de tus empleados con nuestra solución avanzada.
            </p>
          </div>
          <div className={`flex justify-center`}>
            <Button>
              <a
                href="https://wa.me/573158645940?text=Hola%20hr%20solutions,%20estoy%20interesado%20en%20una%20demo%20de%20hr%20chatbot"
                target="_blank"
                rel="noopener noreferrer"
              >
                Prueba Ahora
              </a>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestNow;
