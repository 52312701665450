import * as classes from "./AboutChatBot.module.css";
import Users from "../../assets/Users.svg";
import Globe from "../../assets/Globe.svg";
import Search from "../../assets/Search.svg";
import Compass from "../../assets/Compass.svg";
import AboutCard from "../../components/AboutCard";
import { Typography } from "../../components";

const AboutChatBot = () => {
  const data = [
    {
      heading: "Expertos en Chatbots con Experiencia",
      content:
        "Nuestro equipo de especialistas en chatbots cuenta con años de experiencia en IA conversacional, procesamiento de lenguaje natural y diseño de experiencia de usuario. Se implementará este sistema para que superen las expectativas de tus empelados.",
      img: Users,
    },
    {
      heading: "Integración Fluida de Recursos Humanos",
      content:
        "Nuestro sistema se integra perfectamente con tus sistemas, aplicaciones y canales de comunicación existentes. Ofrece una experiencia de marca unificada y coherente en todos los puntos de contacto.",
      img: Globe,
    },
    {
      heading: "Seguridad Robusta y Cumplimiento",
      content:
        "Protege los datos de tus empleados y asegura el cumplimiento de las normativas de la industria. Nuestro sistema emplea medidas avanzadas de seguridad y protocolos de protección de datos para ofrecer tranquilidad a ti y a tus empleados.",
      img: Search,
    },
    {
      heading: "Soporte Continuo y Optimización",
      content:
        "Aprovecha nuestro equipo de soporte dedicado y los esfuerzos de optimización continua. Monitorizamos el rendimiento de tu chatbot, analizamos los comentarios de los usuarios e implementamos actualizaciones para mejorar la experiencia conversacional con el tiempo.",
      img: Compass,
    },
  ];

  return (
    <div className={`md:px-28 px-7 md:py-16 py-10`}>
      <div className={"flex flex-col items-center justify-center gap-14 md:gap-24"}>
        <div className={`flex flex-col items-center justify-center gap-2`}>
          <h1 className="text-white">
            Acerca de <span className={classes.specialText}>hr chatbot</span>
          </h1>
          <Typography
            typeScale={"subText"}
            className={`${classes.subText2} text-center md:w-5/12`}
          >
            hr chatbot es una sistema integral que permite a las empresas
            tener un agente conversacional inteligente adaptado a sus necesidades
            específicas. Aprovechando tecnologías avanzadas de IA y procesamiento
            de lenguaje natural, ofrecemos soluciones personalizables que
            transforman las interacciones con los empleados y fomentan el crecimiento empresarial.
          </Typography>
        </div>
        <div className="flex flex-col gap-10">
          <div
            className={`md:flex justify-between items-center flex-wrap w-full`}
          >
            <h2 className={`text-center text-white text-3xl`}>
              ¿Qué hace diferente a hr chatbot?
            </h2>
            <Typography
              typeScale={"subText"}
              className={`text-white md:w-4/12 text-center md:visible hidden`}
            >
              Nuestra plataforma combina tecnología avanzada, experiencia en la
              industria y un enfoque centrado en el cliente para ofrecer soluciones
              innovadoras para el sector de recursos humanos.
            </Typography>
          </div>
          <div className={`flex flex-wrap gap-8 justify-center`}>
            {data.map((data, key) => (
              <AboutCard
                key={key}
                title={data.heading}
                description={data.content}
                icon={data.img}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutChatBot;
