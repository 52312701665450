import { Typography } from "../../components";
import { Button } from "../../components/Button";
import scrollDown from "../../assets/ScrollDown.svg";

import * as classes from "./HeroSection.module.css";

const HeroSection = () => {
  return (
    <div
      className={`px-0 md:px-14 gap-20 pb-20 pt-8 md:pt-20 ${classes["hero-container"]}`}
    >
      <div className="flex flex-col items-center">
        <div
          className={`py-16 md:w-3/4 text-center flex flex-col gap-4 items-center`}
        >
          <h1 className="text-white md:w-4/5">
            Potencie los recursos humanos con{" "}
            <span className={classes["focused-text"]}>hr chatbot</span>
          </h1>
          <Typography
            typeScale={"subHeading"}
            className={`${classes["hero-sub-heading"]} w-11/12 md:w-9/12`}
          >
            Revolucione su servicio y compromiso con sus empleados con nuestras
            soluciones avanzadas de hr chatbot.
          </Typography>
        </div>

        <Button>
          <a
            href="https://wa.me/573158645940?text=Hola%20hr%20solutions,%20estoy%20interesado%20en%20una%20demo%20de%20hr%20chatbot"
            target="_blank"
            rel="noopener noreferrer"
          >
            Empezar ahora
          </a>
        </Button>
      </div>

      <div className={`flex items-center flex-col mt-24 gap-4`}>
        <img src={scrollDown} alt="scroll down" width={30} />
        <Typography className={`text-white text-xs font-light`}>
          Explora las funciones de nuestro chatbot
        </Typography>
      </div>
    </div>
  );
};

export default HeroSection;
